/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

interface ModuleEntry {
  moduleName: string
  filePath: string
  content: string
}

const _dtsModules: Omit<ModuleEntry, 'content'>[] = [
  { moduleName: 'ast-types', filePath: '/modules/ast-types/main.d.ts' },
  { moduleName: 'ast-types/lib/node-path', filePath: '/modules/ast-types/lib/node-path.d.ts' },
  { moduleName: 'ast-types/gen/namedTypes', filePath: 'modules/ast-types/gen/namedTypes.d.ts' },
  { moduleName: 'ast-types/lib/types', filePath: '/modules/ast-types/lib/types.d.ts' },
  { moduleName: 'recast', filePath: '/modules/recast/main.d.ts' },
  { moduleName: 'jscodeshift', filePath: '/modules/@types/jscodeshift/index.d.ts' },
  {
    moduleName: 'jscodeshift/src/Collection',
    filePath: '/modules/@types/jscodeshift/src/Collection.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/core',
    filePath: '/modules/@types/jscodeshift/src/core.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/Runner',
    filePath: '/modules/@types/jscodeshift/src/Runner.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/template',
    filePath: '/modules/@types/jscodeshift/src/template.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/testUtils',
    filePath: '/modules/@types/jscodeshift/src/testUtils.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/collections/JSXElement',
    filePath: '/modules/@types/jscodeshift/src/collections/JSXElement.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/collections/Node',
    filePath: '/modules/@types/jscodeshift/src/collections/Node.d.ts',
  },
  {
    moduleName: 'jscodeshift/src/collections/VariableDeclarator',
    filePath: '/modules/@types/jscodeshift/src/collections/VariableDeclarator.d.ts',
  },
]

export async function loadDTSFiles() {
  const parsedDTSFiles: ModuleEntry[] = await Promise.all(
    _dtsModules.map(async ({ moduleName, filePath }) => {
      const response = await fetch(filePath)
      const rawContent = await response.text()

      // TODO: This is a hack to get around the fact that the jscodeshift
      // typings are not in a module. We should fix at a later date.
      const content = `declare module '${moduleName}' {\n${rawContent}\n}`

      return {
        moduleName,
        filePath,
        content,
      }
    })
  )

  return parsedDTSFiles
}
