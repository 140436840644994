/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './components/App/mod'

function initialize() {
  const root = createRoot(document.getElementById('root')!)

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initialize)
} else {
  initialize()
}
