/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

import './polyfills'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import defaultCodemod from '../presets/no-vars.codemod'
import defaultSourceCode from '../presets/no-vars.input'
import { SetStateLike } from '../types/react'
import { createAPI, stringToTransformer } from '../utilities/jscodeshift'

export interface PlaygroundContext {
  /** The source code provided by the user.*/
  sourceCode: string | undefined
  setSourceCode: SetStateLike<string | undefined>
  /** The codemod provided by the user.*/
  codemod: string | undefined
  setCodemod: SetStateLike<string | undefined>

  /** The post-codemod resulting output */
  codemodOutput: string | undefined
}

export const PlaygroundContext = React.createContext<PlaygroundContext>(null as any)

export interface PlaygroundContextProviderProps {
  children: React.ReactNode
}

export const PlaygroundContextProvider: React.FC<PlaygroundContextProviderProps> = ({ children }) => {
  const [sourceCode, setSourceCode] = useState<string | undefined>(defaultSourceCode as unknown as string)
  const [codemod, setCodemod] = useState<string | undefined>(defaultCodemod as unknown as string)
  const [codemodOutput, setCodemodOutput] = useState(sourceCode)

  const runCodemod = useCallback(async () => {
    if (!sourceCode) {
      return
    }

    if (!codemod) {
      setCodemodOutput(sourceCode)
      return
    }

    const transformer = stringToTransformer(codemod)
    const api = createAPI()
    const file = {
      path: 'filestub.js',
      source: sourceCode,
    }

    const result = transformer(file, api, {})

    setCodemodOutput(result || '')
  }, [codemod, sourceCode])

  useEffect(() => {
    runCodemod().catch((error) => {
      setCodemodOutput(error)
    })
  }, [runCodemod])

  const value = useMemo<PlaygroundContext>(
    () => ({
      sourceCode,
      setSourceCode,
      codemod,
      setCodemod,
      codemodOutput,
    }),
    [codemod, codemodOutput, sourceCode]
  )

  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>
}

export const usePlaygroundContext = () => React.useContext(PlaygroundContext)
