/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

import { API, Transform, withParser } from 'jscodeshift'
// import * as ts from 'typescript'
// import {run} from 'jscodeshift/src/Runner'

export class IsomorphicModule<T = any> {
  exports: T

  constructor() {
    this.exports = {} as T
  }
}

export function createAPI(): API {
  const jscodeshift = withParser('ts')

  const api: API = {
    jscodeshift,
    j: jscodeshift,
    stats: () => void 0,
    report: () => void 0,
  }

  return api
}

/**
 * Parses a given codemod string into a jscodeshift transform.
 */
export function stringToTransformer(rawCodemod: string): Transform {
  const parser = new Function('module', rawCodemod)
  const module = new IsomorphicModule()

  parser(module)

  return module.exports
}
