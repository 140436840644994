/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

import { loader, Monaco } from '@monaco-editor/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { PlaygroundContextProvider } from '../../contexts/PlaygroundContext'
import { loadDTSFiles } from '../../utilities/deps'
import { Playground } from '../Playground/mod'
import './styles.css'

export const App: React.FC = () => {
  const [monaco, setMonaco] = useState<Monaco>()

  useEffect(() => {
    loader.init().then((nextMonaco) => {
      nextMonaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        allowNonTsExtensions: true,
        allowSyntheticDefaultImports: true,
        esModuleInterop: true,
        target: nextMonaco.languages.typescript.ScriptTarget.ES2016,
        moduleResolution: nextMonaco.languages.typescript.ModuleResolutionKind.NodeJs,
      })

      setMonaco(nextMonaco)
    })
  }, [])

  useEffect(() => {
    if (!monaco) return

    loadDTSFiles().then((parsedDTSFiles) => {
      for (const { filePath, content } of parsedDTSFiles) {
        console.debug(`Adding ${filePath}`)
        monaco.languages.typescript.typescriptDefaults.addExtraLib(content, filePath)
      }

      // Add JS Codeshift definitions to Monaco
      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        `import { API, FileInfo } from 'jscodeshift/src/core'

        declare global {
          interface IsomorphicModule {
            exports: (file: FileInfo, api: API, options: any) => string | null | undefined | void
          }

          var module: IsomorphicModule
        })`,
        'globals.d.ts'
      )
    })
    ;(window as any).monaco = monaco
  }, [monaco])

  return (
    <PlaygroundContextProvider>
      <main>
        <h1>JS Codeshift Playground</h1>
        {monaco ? <Playground /> : <div>Loading...</div>}
      </main>
    </PlaygroundContextProvider>
  )
}
