/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */

import Editor, { DiffEditor } from '@monaco-editor/react'
import { editor } from 'monaco-editor'
import React from 'react'
import { usePlaygroundContext } from '../../contexts/PlaygroundContext'
import './styles.css'

const defaultEditorOptions: editor.IEditorOptions = {
  automaticLayout: true,
}

const SourceCodeEditor: React.FC = () => {
  const { sourceCode, setSourceCode } = usePlaygroundContext()

  return (
    <Editor className="editor source-code" defaultLanguage="javascript" value={sourceCode} onChange={setSourceCode} />
  )
}

const CodemodEditor: React.FC = () => {
  const { codemod, setCodemod } = usePlaygroundContext()

  return (
    <Editor
      className="editor codemod"
      defaultLanguage="typescript"
      value={codemod}
      onChange={setCodemod}
      options={defaultEditorOptions}
    />
  )
}

const CodemodOutput: React.FC = () => {
  const { codemodOutput, sourceCode } = usePlaygroundContext()

  return (
    <DiffEditor
      className="codemod-output"
      original={sourceCode}
      originalLanguage="javascript"
      options={defaultEditorOptions}
      modified={codemodOutput}
    />
  )
}

export const Playground: React.FC = () => {
  return (
    <div className="playground">
      <section className="user-inputs">
        <div className="editor-container">
          <h2>Source Code</h2>
          <SourceCodeEditor />
        </div>
        <div className="editor-container">
          <h2>Codemod</h2>
          <CodemodEditor />
        </div>
      </section>

      <section>
        <h2>Output</h2>
        <CodemodOutput />
      </section>
    </div>
  )
}
